<template>
  <div class="pdp-sticky-cta-pricing">
    <template v-if="isLoading">
      <v-skeleton-loader
        class="mb-2"
        type="heading"
        width="200"
      ></v-skeleton-loader>
      <v-skeleton-loader type="text" width="80%"></v-skeleton-loader>
      <v-skeleton-loader type="text" width="40%"></v-skeleton-loader>
    </template>

    <template v-else>
      <div class="product-price">฿{{ mainPricing | currency }}</div>
      <template>
        <div class="pricing-installment">
          หรือ ฿{{ (mainPricing / INSTALLMENT.MAX) | currency }} /
          เดือน ผ่อน 0% นานสูงสุด {{ INSTALLMENT.MAX }} เดือน
        </div>
        <!-- Show only desktop -->
        <v-btn
          text
          class="btn-link dp-none dp-md-block"
          @click="$emit('openPaymentMethod')"
        >
          ดูเพิ่มเติมเกี่ยวกับการผ่อนชำระ
          <v-icon>
            mdi-chevron-right
          </v-icon>
        </v-btn>
      </template>
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { INSTALLMENT } from '@/config/index'

export default {
  name: 'PDPStickyCTAPricing',
  data() {
    return {
      INSTALLMENT,
    }
  },
  computed: {
    ...mapState({
      pricing: state => state.cart.cartSummaryData,
      isLoading: state => state.cart.isLoading,
    }),

    mainPricing() {
      return this.pricing?.final_price ?? 0
    },
  },
}
</script>

<style lang="stylus" scoped>
.pdp-sticky-cta-pricing {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: 4px;

    .product-price {
        font-size: 1rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1.25012rem;
        font-size: .875rem;
        font-weight: 700;

        @media (min-width: 64rem) {
            font-size: 1.5rem;
            font-style: normal;
            font-weight: 600;
            line-height: 1.875rem;
            letter-spacing: .03rem;
        }
    }

    .pricing-installment{
        font-size: .875rem;
        font-weight: 500;
        line-height: 1.25012rem;

        @media (min-width: 64rem) {
                font-size: 1.125rem;
                font-weight: 400;
        }
    }

    .btn-link {
        padding 0 !important
        color: $color-bnn;
        cursor pointer

        &:hover:before {
            background: transparent !important
        }

        .v-icon {
            color: $color-bnn
        }
    }
}
</style>
